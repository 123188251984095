import { JSX, Match, Show, Switch } from "solid-js";

import { Optional } from "~/types";
import { VoucherHighlight } from "~/server/types/brand";
import {
  cannotBeClubbedWithOffers,
  cardCanBeClubbed,
  cardsCannotBeClubbed,
  clubWithOffers,
  multipleTimes,
  offline,
  online,
  onlineOffline,
  singleTime,
  subscription,
  validForOneYear,
} from "~/assets/brand_highlight_svg_icons";

type HighlightProps = {
  iconUrlBuilder: () => JSX.Element;
  title: string;
  description: string;
};

function Highlight(props: HighlightProps) {
  return (
    <div class="inline-flex items-start justify-start gap-2 self-stretch">
      <div class="relative h-8 w-8">
        <div class="absolute left-[1.99px] top-[3.97px] h-[24.06px] w-7 stroke-[#000] darkTheme:stroke-[#ABABAB]">
          {props.iconUrlBuilder()}
        </div>
        <div class="absolute left-[9px] top-[9px] h-3.5 w-3.5">
          <div class="absolute left-[-0px] top-[-0px] h-3.5 w-3.5"></div>
        </div>
      </div>
      <div class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-0.5">
        <h3 class="self-stretch text-mediumBold text-basePrimaryDark lg:text-bold">
          {props.title}
        </h3>
        <div class="inline-flex items-start justify-start gap-3 self-stretch">
          <div class="shrink grow basis-0 text-medium text-textNormal">
            {props.description}
          </div>
        </div>
      </div>
    </div>
  );
}

type HighlightsSectionProps = {
  voucherHighlights: VoucherHighlight[];
};

function VoucherHighlightsSection(props: HighlightsSectionProps) {
  const highlights = props.voucherHighlights;

  return (
    <div class="grid w-full grid-cols-1 gap-2 lg:grid-cols-2">
      <Switch>
        <Match when={highlights.includes("ONLINE_OFFLINE")}>
          <Highlight
            iconUrlBuilder={onlineOffline}
            title={"Use to online or in-store"}
            description={"Works on app / website / in-store"}
          />
        </Match>
        <Match when={highlights.includes("ONLINE")}>
          <Highlight
            iconUrlBuilder={online}
            title={"Use to pay online"}
            description={"Works on App / website"}
          />
        </Match>
        <Match when={highlights.includes("OFFLINE")}>
          <Highlight
            iconUrlBuilder={offline}
            title={"Use to pay in-store"}
            description={"Redeem at checkout counter"}
          />
        </Match>
        <Match when={highlights.includes("SUBSCRIPTION")}>
          <Highlight
            iconUrlBuilder={subscription}
            title={"Use to unlock subscription"}
            description={"Works on App / website"}
          />
        </Match>
      </Switch>

      <Switch>
        <Match when={highlights.includes("MULTIPLE_TIMES")}>
          <Highlight
            iconUrlBuilder={multipleTimes}
            title={"Can be used multiple times"}
            description={"Till full balanced is utilized"}
          />
        </Match>
        <Match when={highlights.includes("SINGLE_TIME")}>
          <Highlight
            iconUrlBuilder={singleTime}
            title={"Disclaimer: Single time use card"}
            description={"Full balance should be used in 1 go"}
          />
        </Match>
      </Switch>

      <Switch>
        <Match when={highlights.includes("CLUB_WITH_OFFERS")}>
          <Highlight
            iconUrlBuilder={clubWithOffers}
            title={"Club with brand offers"}
            description={"Eg. Brand coupons work on top"}
          />
        </Match>
        <Match when={highlights.includes("CANT_BE_CLUBBED_WITH_OFFERS")}>
          <Highlight
            iconUrlBuilder={cannotBeClubbedWithOffers}
            title={"Brand offers can’t be clubbed"}
            description={"Eg. Brand coupons won’t work on top"}
          />
        </Match>
      </Switch>

      <Switch>
        <Match when={highlights.includes("CARDS_CAN_BE_CLUBBED")}>
          <Highlight
            iconUrlBuilder={cardCanBeClubbed}
            title={"Multiple cards can be clubbed"}
            description={"In one purchase on brand store"}
          />
        </Match>
        <Match when={highlights.includes("CARDS_CANT_BE_CLUBBED")}>
          <Highlight
            iconUrlBuilder={cardsCannotBeClubbed}
            title={"Multiple cards can’t be clubbed"}
            description={"In one purchase on brand store"}
          />
        </Match>
      </Switch>

      <Switch>
        <Match when={highlights.includes("VALID_FOR_ONE_YEAR")}>
          <Highlight
            iconUrlBuilder={validForOneYear}
            title={"Valid for 1 year"}
            description={"After you make gift card purchase"}
          />
        </Match>
        <Match when={highlights.includes("VALID_FOR_NINE_MONTHS")}>
          <Highlight
            iconUrlBuilder={validForOneYear}
            title={"Valid for 9 months"}
            description={"After you make gift card purchase"}
          />
        </Match>
        <Match when={highlights.includes("VALID_FOR_SIX_MONTHS")}>
          <Highlight
            iconUrlBuilder={validForOneYear}
            title={"Valid for 6 months"}
            description={"After you make gift card purchase"}
          />
        </Match>
        <Match when={highlights.includes("VALID_FOR_THREE_MONTHS")}>
          <Highlight
            iconUrlBuilder={validForOneYear}
            title={"Valid for 3 months"}
            description={"After you make gift card purchase"}
          />
        </Match>
      </Switch>
    </div>
  );
}

type AboutThisCardProps = {
  voucherDescription: Optional<string>;
  voucherHighlights: VoucherHighlight[];
};

export function AboutThisVoucher(props: AboutThisCardProps) {
  return (
    <div class="flex h-fit w-full flex-col items-start justify-start gap-4 self-stretch lg:gap-6">
      <div class="flex h-fit flex-col items-start justify-start gap-3 ">
        <h2 class="self-stretch text-h5 text-textDark lg:text-h4">
          About this gift card
        </h2>

        <div class="self-stretch text-medium text-basePrimaryDark darkTheme:text-textNormal lg:text-normal">
          <Show
            when={props.voucherDescription}
            fallback={
              <>
                Your prepaid ticket to a world of purchases, easily redeemable
                across eligible product categories. Explore millions of products
                and find the perfect gift for any occasion with gift cards.
              </>
            }
          >
            {props.voucherDescription}
          </Show>
        </div>
      </div>
      <Show when={props.voucherHighlights}>
        <VoucherHighlightsSection voucherHighlights={props.voucherHighlights} />
      </Show>
    </div>
  );
}
