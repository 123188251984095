export function clubWithOffers() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.7401 26.28H8.62673C7.0214 26.28 5.71873 24.9787 5.71873 23.372V21.4627C5.71873 20.692 5.41206 19.952 4.86673 19.4067L3.51606 18.056C2.38006 16.92 2.38006 15.08 3.51606 13.944L4.86673 12.5933C5.41206 12.048 5.71873 11.3093 5.71873 10.5373V8.62801C5.71873 7.02268 7.02006 5.72001 8.62673 5.72001H10.5361C11.3067 5.72001 12.0467 5.41334 12.5921 4.86801L13.9427 3.51734C15.0787 2.38134 16.9187 2.38134 18.0547 3.51734L19.4054 4.86801C19.9507 5.41334 20.6907 5.72001 21.4614 5.72001H23.3707C24.9761 5.72001 26.2787 7.02134 26.2787 8.62801V10.5373C26.2787 11.308 26.5854 12.048 27.1307 12.5933L28.4814 13.944C29.6174 15.08 29.6174 16.92 28.4814 18.056L27.1307 19.4067C26.5854 19.952 26.2787 20.692 26.2787 21.4627V23.372C26.2787 24.9773 24.9774 26.28 23.3707 26.28H21.4614C20.6907 26.28 19.9507 26.5867 19.4054 27.132L18.0547 28.4827C16.9187 29.6187 15.0787 29.6187 13.9427 28.4827L11.7401 26.28Z"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.5013 21.3333H11.5013C10.7653 21.3333 10.168 20.736 10.168 20V15.168C10.168 14.432 10.7653 13.8347 11.5013 13.8347H20.5C21.236 13.8347 21.8333 14.432 21.8333 15.168V20C21.8333 20.736 21.236 21.3333 20.5013 21.3333Z"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0893 13.8413C16.828 13.8413 18.352 13.6013 19.3213 12.5893C20.0346 11.844 20.0346 10.6373 19.3213 9.89201C18.608 9.14667 17.4506 9.14667 16.7373 9.89201C15.6453 11.0333 16.0893 13.8413 16.0893 13.8413V13.8413Z"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9115 13.8413C15.1729 13.8413 13.6489 13.6013 12.6795 12.5893C11.9662 11.844 11.9662 10.6373 12.6795 9.89201C13.3929 9.14667 14.5502 9.14667 15.2635 9.89201C16.3555 11.0333 15.9115 13.8413 15.9115 13.8413V13.8413Z"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.9987 13.84V21.3334"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function cardsCannotBeClubbed() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.9997 22.64L15.9863 28"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.9867 28L4 22.64"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.9997 16L15.9863 21.36"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.9867 21.36L4 16"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 9.36533L15.9827 14.732L28 9.36533L16.0173 4L4 9.36533Z"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g filter="url(#filter0_d_234_9476)">
        <path
          d="M9.63322 25.9962L22.3658 6.00375"
          stroke="inherit"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_234_9476"
          x="9.0332"
          y="5.40367"
          width="13.9336"
          height="23.1926"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_234_9476"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_234_9476"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export function cardCanBeClubbed() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M27.9997 22.64L15.9863 28"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.9867 28L4 22.64"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.9997 16L15.9863 21.36"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.9867 21.36L4 16"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 9.36533L15.9827 14.732L28 9.36533L16.0173 4L4 9.36533Z"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function cannotBeClubbedWithOffers() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.5013 21.3333H11.5013C10.7653 21.3333 10.168 20.736 10.168 20V15.168C10.168 14.432 10.7653 13.8347 11.5013 13.8347H20.5C21.236 13.8347 21.8333 14.432 21.8333 15.168V20C21.8333 20.736 21.236 21.3333 20.5013 21.3333Z"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0893 13.8413C16.828 13.8413 18.352 13.6013 19.3213 12.5893C20.0346 11.844 20.0346 10.6373 19.3213 9.89201C18.608 9.14667 17.4506 9.14667 16.7373 9.89201C15.6453 11.0333 16.0893 13.8413 16.0893 13.8413V13.8413Z"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9115 13.8413C15.1729 13.8413 13.6489 13.6013 12.6795 12.5893C11.9662 11.844 11.9662 10.6373 12.6795 9.89201C13.3929 9.14667 14.5502 9.14667 15.2635 9.89201C16.3555 11.0333 15.9115 13.8413 15.9115 13.8413V13.8413Z"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.9987 13.84V21.3334"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g filter="url(#filter0_d_234_9481)">
        <path
          d="M9.57061 26.3197L22.5117 6.00001"
          stroke="inherit"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.7401 26.28H8.62673C7.0214 26.28 5.71873 24.9787 5.71873 23.372V21.4627C5.71873 20.692 5.41206 19.952 4.86673 19.4067L3.51606 18.056C2.38006 16.92 2.38006 15.08 3.51606 13.944L4.86673 12.5933C5.41206 12.048 5.71873 11.3093 5.71873 10.5373V8.62801C5.71873 7.02268 7.02006 5.72001 8.62673 5.72001H10.5361C11.3067 5.72001 12.0467 5.41334 12.5921 4.86801L13.9427 3.51734C15.0787 2.38134 16.9187 2.38134 18.0547 3.51734L19.4054 4.86801C19.9507 5.41334 20.6907 5.72001 21.4614 5.72001H23.3707C24.9761 5.72001 26.2787 7.02134 26.2787 8.62801V10.5373C26.2787 11.308 26.5854 12.048 27.1307 12.5933L28.4814 13.944C29.6174 15.08 29.6174 16.92 28.4814 18.056L27.1307 19.4067C26.5854 19.952 26.2787 20.692 26.2787 21.4627V23.372C26.2787 24.9773 24.9774 26.28 23.3707 26.28H21.4614C20.6907 26.28 19.9507 26.5867 19.4054 27.132L18.0547 28.4827C16.9187 29.6187 15.0787 29.6187 13.9427 28.4827L11.7401 26.28Z"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_234_9481"
          x="8.9707"
          y="5.39993"
          width="14.1406"
          height="23.5199"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_234_9481"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_234_9481"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export function singleTime() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.334 13.0833V11.9167C11.334 11.5945 11.5952 11.3333 11.9173 11.3333H20.084C20.4062 11.3333 20.6673 11.5945 20.6673 11.9167V13.0833"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.6667 13.0833H11.3333C11.0112 13.0833 10.75 13.3445 10.75 13.6667V20.0833C10.75 20.7277 11.2723 21.25 11.9167 21.25H20.0833C20.7277 21.25 21.25 20.7277 21.25 20.0833V13.6667C21.25 13.3445 20.9888 13.0833 20.6667 13.0833Z"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.3327 15.4167C18.3327 16.7053 17.288 17.75 15.9993 17.75C14.7107 17.75 13.666 16.7053 13.666 15.4167"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g filter="url(#filter0_d_234_9500)">
        <path
          d="M9.93089 26.2452L22.7924 6.05044"
          stroke="inherit"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <path
        d="M1.99414 15.0049L3.99497 13.0041L5.99581 15.0049"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.0056 17.0004L28.0047 19.0012L26.0039 17.0004"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.23242 9.05043C9.53453 4.36608 15.6907 2.67473 20.9226 5.01446C26.1545 7.35419 28.9981 13.0703 27.708 18.6544"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.7611 22.9496C22.459 27.6339 16.3028 29.3252 11.0709 26.9855C5.83902 24.6458 2.99547 18.9297 4.28549 13.3456"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_234_9500"
          x="9.33008"
          y="5.45035"
          width="14.0625"
          height="23.395"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_234_9500"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_234_9500"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export function multipleTimes() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 14.9343L3.14285 12.7914L5.2857 14.9343"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.0005 17.0714L28.8577 19.2143L26.7148 17.0714"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.53906 8.55716C9.07555 3.54032 15.6687 1.72892 21.272 4.23472C26.8752 6.74053 29.9206 12.8623 28.539 18.8429"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.4538 23.4429C22.9174 28.4597 16.3242 30.2711 10.721 27.7653C5.1177 25.2595 2.07231 19.1377 3.4539 13.1572"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.334 13.0833V11.9166C11.334 11.5945 11.5952 11.3333 11.9173 11.3333H20.084C20.4062 11.3333 20.6673 11.5945 20.6673 11.9166V13.0833"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.6667 13.0833H11.3333C11.0112 13.0833 10.75 13.3445 10.75 13.6666V20.0833C10.75 20.7276 11.2723 21.25 11.9167 21.25H20.0833C20.7277 21.25 21.25 20.7276 21.25 20.0833V13.6666C21.25 13.3445 20.9888 13.0833 20.6667 13.0833Z"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.3327 15.4167C18.3327 16.7054 17.288 17.75 15.9993 17.75C14.7107 17.75 13.666 16.7054 13.666 15.4167"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function offline() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.93783 14.7072V26.3543"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.0609 14.7072V26.3543"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.5881 26.3532V21.1772C18.5881 19.7481 17.4292 18.5892 16.0001 18.5892C14.571 18.5892 13.4121 19.7481 13.4121 21.1772V26.3532"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.35352 26.3543H27.6478"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.03304 14.706C5.05536 14.692 3.76602 12.7341 4.62325 11.0465L6.62073 7.11323C7.07613 6.2164 8.03353 5.64685 9.0841 5.64685H22.9162C23.9668 5.64685 24.9242 6.2164 25.3796 7.11323L27.3783 11.0465C28.2355 12.7341 26.9461 14.692 24.9685 14.706C23.3181 14.706 21.9798 13.5786 21.9798 12.1867V12.1308C21.9798 13.5529 20.6416 14.706 18.9912 14.706C17.3408 14.706 16.0025 13.5529 16.0025 12.1308C16.0025 13.5529 14.6642 14.706 13.0138 14.706C11.3634 14.706 10.0252 13.5529 10.0252 12.1308V12.1867C10.0217 13.5786 8.68344 14.706 7.03304 14.706Z"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0215 12.1418L11.6732 5.9585"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.9902 12.1418L20.3385 5.9585"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 12.1047L16 5.9585"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function online() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4668 17.3334L15.8001 18.6667L17.8001 16.6667"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24 25.3333C24 26.8061 22.8061 28 21.3333 28H10.6667C9.19391 28 8 26.8061 8 25.3333V6.66667C8 5.19391 9.19391 4 10.6667 4H21.3333C22.8061 4 24 5.19391 24 6.66667V25.3333"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.3327 4V6.66667C19.3327 7.03486 19.0342 7.33333 18.666 7.33333H13.3327C12.9645 7.33333 12.666 7.03486 12.666 6.66667V4"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function validForOneYear() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0029 3.995V7.99666"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99898 3.995V7.99666"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3333 28.005H24.0044C26.2145 28.005 28.0061 26.2134 28.0061 24.0033V9.99752C28.0061 7.78746 26.2145 5.99585 24.0044 5.99585H7.99776C5.7877 5.99585 3.99609 7.78746 3.99609 9.99752V18.6678"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6656 22.6694L7.33091 26.0042L5.33008 24.0033"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99805 14.6662H22.003"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function onlineOffline() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.666 24.0034H16.0016"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.3367 20.0017V25.3372C21.3367 26.8106 20.1423 28.005 18.669 28.005H7.99786C6.52448 28.005 5.33008 26.8106 5.33008 25.3372V6.66277C5.33008 5.1894 6.52448 3.995 7.99786 3.995H11.9995"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.1337 9.56799V16"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.8056 16V9.47461"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.3369 16V13.3322"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.4668 16H27.4184"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.3359 9.64671C22.3312 9.64681 23.2159 9.01266 23.5355 8.07005C23.5568 8.02169 23.6047 7.99048 23.6576 7.99048C23.7104 7.99048 23.7583 8.02169 23.7796 8.07005C24.0997 9.01286 24.9849 9.64697 25.9805 9.64671"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.6914 9.64671C17.6869 9.64656 18.5719 9.01261 18.8923 8.07005C18.9136 8.02169 18.9615 7.99048 19.0144 7.99048C19.0672 7.99048 19.1151 8.02169 19.1364 8.07005C19.456 9.01266 20.3407 9.64681 21.336 9.64671"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.9806 9.64668C26.6826 9.64579 27.3339 9.28091 27.7012 8.68271C28.0686 8.08451 28.0994 7.3386 27.7827 6.71213L26.905 4.97807C26.5977 4.37451 25.9776 3.99459 25.3003 3.995H17.3717C16.6955 3.99635 16.0772 4.3766 15.771 4.97941L14.8933 6.71346C14.5766 7.33993 14.6074 8.08585 14.9748 8.68404C15.3421 9.28224 15.9934 9.64713 16.6954 9.64802V9.64802"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function subscription() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0029 3.995V7.99666"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99898 3.995V7.99666"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="3.99609"
        y="5.99585"
        width="24.01"
        height="22.0092"
        rx="3"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.0678 18.7048L14.4004 20.3722L16.0678 22.0396"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.4148 20.3583C14.8302 20.4154 15.2489 20.4448 15.6681 20.4463C18.799 20.4463 21.3372 18.9533 21.3372 17.1116C21.3372 15.2699 18.7989 13.7769 15.6681 13.7769C13.8265 13.6755 12.0222 14.3235 10.666 15.5735"
        stroke="inherit"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
